@import '../fonts';

$timeline-font: encode-sans, Roboto, Arial, sans-serif;
$timeline-font-title: encode-sans, Roboto, Arial, sans-serif;
$timeline-font-special: encode-sans, Roboto, Arial, sans-serif;
$timeline-font-condensed: encode-sans-condensed, encode-sans, Roboto, Arial, sans-serif;

$timeline-section-subtitle-font-weight: 900;

$timeline-color: #19a1a1;
$timeline-color-header: #19a1a1;
$timeline-color-02: rgba(25, 161, 161, 0.2);
$timeline-color-08: rgba(25, 161, 161, 0.8);
$timeline-color-09: rgba(25, 161, 161, 0.9);
$timeline-color-1: rgba(25, 161, 161, 1);
$timeline-color-print-level-1: #19a1a1;
$timeline-color-print-level-2: #d3e6e6;
$timeline-color-print-level-3: lightgray;
$timeline-color-level-1: white;
$timeline-color-level-2: rgba(255, 255, 255, 0.9);
$timeline-color-level-3: rgba(255, 255, 255, 0.85);
$timeline-color-light: #d3e6e6;
$timeline-color-light-rgb: #d3e6e6;
$timeline-color-red: #d9534f;
$timeline-color-gray: #dfdfdf;
$timeline-chart-background-color: #dfdfdf52;
$timeline-color-gray-light-dark: #bdbdbd;
$timeline-color-black: #000000;
$timeline-color-black-light-50: rgb(0 0 0 / 56%);
$timeline-color-black-hidden: #2e2e2e;
$timeline-color-light-black: #4c4c4c;
$timeline-color-light-black-1: #00000091;
$timeline-color-darkgray: gray;
$timeline-color-green-l: #0080008f;
$timeline-color-lightgrey: lightgrey;
$timeline-color-lightgrey-1: #00000059;
$timeline-color-grey-l: #9a9a9a;
$timeline-color-grey-2: #7c7c7c;
$timeline-color-pressed: rgba(0, 134, 227, 0.4);
$timeline-color-white: white;
$timeline-color-whitesmoke: whitesmoke;
$timeline-color-white-00: rgba(255, 255, 255, 0);
$timeline-color-white-03: rgba(255, 255, 255, 0.3);
$timeline-color-white-04: rgba(255, 255, 255, 0.4);
$timeline-color-white-045: rgba(255, 255, 255, 0.45);
$timeline-color-white-06: rgba(255, 255, 255, 0.6);
$timeline-color-white-065: rgba(255, 255, 255, 0.65);
$timeline-color-white-07: rgba(255, 255, 255, 0.7);
$timeline-color-white-08: rgba(255, 255, 255, 0.8);
$timeline-color-white-09: rgba(255, 255, 255, 0.9);
$timeline-color-white-096: rgba(255, 255, 255, 0.96);
$timeline-color-background: #d3e6e6;
$timeline-color-background-light-light: #f0f8ff;
$timeline-color-table-background-hover: #f5f5f5;
$timeline-color-light-light-gray: #f1efef;
$timeline-color-extra-light-gray: #ececec;
$timeline-color-light-light: #7497ce;
$timeline-color-super-light-gray: #f5f5f5;
$timeline-color-warn: rgba(204, 0, 0, 1);
$timeline-color-gradient: orange;
$timeline-color-section-tree-item-active: #cac7c373;
$timeline-questionnaire-progress: linear-gradient(to top, #5b85d1 0%, #6894e5 100%);
$timeline-questionnaire-answers-progress: #fb7822;
$timeline-questionnaire-progress-inactive: linear-gradient(to top, #80d0d1 0%, #c0e8e8 100%);
$timeline-questionnaire-progress-shadow: 0 5px 0 0 #80b2b2, 0 10px 5px #999;
$timeline-questionnaire-progress-shadow-inactive: 0 2px 0 0 #a2b0cb, 0 2px 3px #cccccc;
$timeline-questionnaire-count: linear-gradient(to top, #017273 0%, #00a0a2 100%);
$timeline-questionnaire-count-shadow: 0 5px 0 0 #31405a, 0 10px 5px #999;
$timeline-questionnaire-count-matching-shadow: 0 5px 0 0 #d9d9d9;
$timeline-questionnaire-count-matching-shadow-active: 0 5px 0 0 #31405a;
$timeline-color-complete: #19a1a1;
$timeline-color-stop: rgba(255,233,236,1);
$timeline-color-wait: rgba(255, 255, 0, 0.07);
$timeline-color-prep-wait: rgba(198,232,182, 0.5);
$timeline-color-ready: rgba(185,213,227,1);
$timeline-color-light-gray-05: rgba(211, 211, 211, 0.5);
$timeline-color-cast: orange;
$timeline-color-orange: orange;
$timeline-color-orange-dark: rgb(255, 140, 0);
$timeline-color-orange-05: rgb(255 165 0 / 0.5);
$timeline-color-orange-lite: rgba(255, 165, 0, 0.7);
$timeline-color-dark-green: darkgreen;
$timeline-color-lightblue: lightblue;
$timeline-color-lime: lime;
$timeline-color-green-2: #44AC42;
$timeline-text-gray: rgba(0, 0, 0, .54);
$timeline-toolbar-button-hover: #a9a9a9;
$timeline-text-lightgray: rgba(0, 0, 0, .12);
$timeline-event-publish: rgba(217, 234, 211, 1);
$timeline-event-draft: rgba(244, 204, 204, 1);
$timeline-event-prep-wrap-line: rgba(239, 239, 239, 1);
$timeline-event-start-end-line: rgba(207, 226, 243, 1);
$timeline-color-green: green;
$timeline-color-lightgreen: lightgreen;
$timeline-color-disabled-text: rgba(0,0,0,.26);
$timeline-color-disabled-1: rgba(196, 196, 196, 0.68);
$timeline-color-checkbox-default-low: rgba(128, 128, 128, 0.45);
$timeline-color-column-start: rgba(128, 128, 128, 0.25);
$timeline-color-column-start-hover: rgba(128, 128, 128, 0.32);
$timeline-color-column-end: rgba(128, 128, 128, 0.12);
$timeline-color-column-end-hover: rgba(128, 128, 128, 0.19);
$timeline-color-column-button-background: #4188f9;
$timeline-color-blanchedalmond: blanchedalmond;
$timeline-color-dirty-white: #e2e2e2;
$timeline-color-any-time: linear-gradient(168deg, rgba(255,255,255,1) 36%, rgba(0,102,204,0.3) 100%);
$timeline-color-gray2: #d3d3d3a8;
$timeline-color-main-action-menu: linear-gradient(165deg, rgba(31,31,31,1) 35%, rgba(107,107,107,0.93) 63%, rgba(0,0,0,0.95) 88%);
$timeline-color-main-action-menu-divider: rgb(131, 154, 177);
$timeline-color-main-action-menu-icon: rgb(111, 161, 211);
$timeline-color-moods-panel: rgba(254, 254, 255, 0.18);
$timeline-color-moods-background: #7ba1c1;
$timeline-color-souffleur: #cfd2d8;
$timeline-color-action-hover: #909090;
$background-color-012: #2a2a72;
$background-color-3-4-5: #bdd6ee;
$background-current-line-color: #fb7822;
$background-current-line-color-light: #f59759;
$background-current-line-hover-color: #fb7822e6;
$background-tooltip: rgb(254, 213, 156);
$background-account-actions: rgb(189, 189, 189, 0.32);
$background-orange-hover: rgba(255, 165, 0, 0.1);
$pulse-color-on: rgba(25, 161, 161, 0.2);
$pulse-color-off: rgba(25, 161, 161, 0);
$timeline-color-dark-darkgray: darkgray;
$timeline-color-disable-darkgray: #797979;
$timeline-color-true-red: red;
$timeline-color-user-status-unregistered: rgba(255, 0, 0, 0.2);
$timeline-color-user-status-registered: rgba(25, 161, 161, 0.2);
$timeline-color-user-status-other: rgba(169, 169, 169, 0.2);
$timeline-color-agenda-registration: black;
$timeline-color-registration-completed: black;
$timeline-event-not-correct-row: #F4B3AD;
$timeline-color-blue: blue;
$timeline-feedback-star-color: #FAC917;
$timeline-black-thumbnail-not-active: #282828;
$timeline-color-black-light: #343434;
$timeline-color-white-light-transparent: #ffffff36;
$timeline-color-chocolate: chocolate;
$timeline-menu-button-background: #f1f3f5;
$timeline-color-error: #EF4444;

$color-picker-c1: #19a1a1;
$color-picker-c2: #000000;
$color-picker-c3: #ffb653;
$color-picker-c4: #e4e4e4;
$color-picker-c5: #cceaec;
$color-picker-c6: #aeaeae;
$color-picker-c7: #304adf;
$color-picker-c8: #e13900;
$color-picker-c9: #4bb325;
$color-picker-c10: #b315a3;
$color-picker-c11: #9e903e;
$color-picker-c12: #9d34e6;

$timeline-color-hint-active: #fff5b9;
$timeline-color-hint-active-hover: #fffa98;

$timeline-color-open: rgba(217, 234, 211, 1);
$timeline-color-planned: rgba(255, 242, 204, 1);
$timeline-color-draft: rgba(244, 204, 204, 1);

$timeline-color-cover-down: #f1e084;
$timeline-personal-pin: yellow;

$timeline-progress-background: rgba(220, 220, 220, 0.7);
$timeline-progress: gray;
$timeline-disabled-background: rgba(220, 220, 220, 0.9);

$timeline-tree-color: #343A40;
$timeline-tree-active-mode-switcher: #495057;
$timeline-tree-mode-hover-switcher: #ffffff05;
$timeline-tree-active-mode-hover-switcher: #343a40bd;
$timeline-free-slot-background: #858585;
$timeline-free-slot-background-hover-color: #a3a3a3;
$timeline-content-scroller-meeting-panel-background: #868E96;
$timeline-content-scroller-meeting-button-hover: #7a7a7a;
$timeline-content-scroller-background: #DEE2E6;
$timeline-content-scroller-background-light: #F1F3F5;
$timeline-content-scroller-active-content: #121417c4;
$timeline-content-scroller-content-hover: #8080800f;
$timeline-content-scroller-active-presenter-content: #fb7822;
$timeline-content-editor-scrollbar-thumb: #e3e3e3;
$timeline-content-editor-track-piece: #f5f5f5;

$toolbar-right-button-color: #343A40;
$toolbar-right-button-icons-color: #868E96;

$headers-background-color: rgba(181, 181, 181, 0.12);
$grid-row-bored-color: rgba(0, 0, 0, .12);
$grid-selected-row-background-color: rgb(233 233 233 / 12%);

@font-face {
  font-family: encode-sans;
  src: url('/assets/fonts/timeline/encode-sans/EncodeSans-Light.woff2') format('woff2'),
  url('/assets/fonts/timeline/encode-sans/EncodeSans-Light.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: encode-sans;
  src: url('/assets/fonts/timeline/encode-sans/EncodeSans-Regular.woff2') format('woff2'),
  url('/assets/fonts/timeline/encode-sans/EncodeSans-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: encode-sans;
  src: url('/assets/fonts/timeline/encode-sans/EncodeSans-Medium.woff2') format('woff2'),
  url('/assets/fonts/timeline/encode-sans/EncodeSans-Medium.ttf') format('ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: encode-sans;
  src: url('/assets/fonts/timeline/encode-sans/EncodeSans-Bold.woff2') format('woff2'),
  url('/assets/fonts/timeline/encode-sans/EncodeSans-Bold.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'encode-sans-condensed';
  src: url('/assets/fonts/timeline/encode-sans-condensed/EncodeSans_Condensed-Light.woff2?') format('woff2'),
  url('/assets/fonts/timeline/encode-sans-condensed/EncodeSans_Condensed-Light.ttf?') format('ttf');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'encode-sans-condensed';
  src: url('/assets/fonts/timeline/encode-sans-condensed/EncodeSans_Condensed-Regular.woff2?') format('woff2'),
  url('/assets/fonts/timeline/encode-sans-condensed/EncodeSans_Condensed-Regular.ttf?') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'encode-sans-condensed';
  src: url('/assets/fonts/timeline/encode-sans-condensed/EncodeSans_Condensed-Medium.woff2?') format('woff2'),
  url('/assets/fonts/timeline/encode-sans-condensed/EncodeSans_Condensed-Medium.ttf?') format('ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'encode-sans-condensed';
  src: url('/assets/fonts/timeline/encode-sans-condensed/EncodeSans_Condensed-Bold.woff2?') format('woff2'),
  url('/assets/fonts/timeline/encode-sans-condensed/EncodeSans_Condensed-Bold.ttf?') format('ttf');
  font-weight: 800;
  font-style: normal;
}

$scrollbar-width: 5px;

$timeline-border-color: #ADB5BD;
$timeline-background-color: #DEE2E6;
$timeline-menu-background-color: #F1F1F1;
$timeline-background-color-hover: #E3E3E3;
$timeline-background-color-hover-light: #E3E3E34D;
$timeline-background-color-active: #DBDBDB;
$timeline-table-row-number-column: #9D9D9D;
$timeline-background-color-light: #F1F3F5;
$timeline-background-color-dark: #868e96;
$timeline-border-color-orange: #FC7723;
$timeline-border-color-orange-hover: #e75f05;
$timeline-background-color-orange-02: rgb(255 165 0 / 20%);
$timeline-background-color-orange: #F87832;
$timeline-border-color-orange-light: rgba(248, 120, 50, 0.2);
$timeline-table-row-hover: #FFBA8FEF;
$timeline-scrollbar-background-color: #F1F1F1;
$timeline-progress-not-used-background-color: #D9D9D9;
$timeline-progress-color: #44AC42;
$timeline-grid-row-border: #ddd;
$timeline-background-color-switch-off: rgba(0,0,0,.38);
$timeline-menu-icon-color: rgba(0,0,0,.54);
$radio-button-selector-border-color: #607d8b;
$note-pin-background: #F7DAED;
$note-pin-color: #961574;
$note-pin-background-text-color: #DA9FC9;
$note-pin-background-text-hover-color: rgb(218 159 201 / 50%);
$note-line-background: #BEEBE7;
$note-line-color: #167B7D;
$note-line-background-text-color: #8BBDBE;
$note-line-background-text-hover-color: rgb(139 189 190 / 50%);
$timeline-native-checkbox-orange: rgb(217 125 80);
$timeline-toolbar-button-background: #262B2F;
$timeline-clear-button-color: #FC575E;
$timeline-priority-color-red: #F64040;
$timeline-priority-background-color: #f7f1f1;
$timeline-menu-background: #E9E9EA;
$timeline-menu-color: #828282;
$note-pin-background-toolbar: #FDF3FA;
$note-line-background-toolbar: #EAF9F8;
$note-t-pin-background-toolbar: #fff8dc;
$note-t-pin-hover-toolbar: #fff0b3;
$note-t-pin-active-toolbar: #e1b400;
$note-eye-background-toolbar: #f5f5f5;
$grid-row-background-color: rgba(0, 0, 0, 0.02);
$timeline-color-correct-count: rgb(7 157 7);
$timeline-color-error-count: rgb(221 130 133);
$timeline-color-accepting-border: #86EFAD;
$timeline-color-accepting-background: #F0FDF5;
$timeline-correct-answer-color: #15803D;
$timeline-correct-answer-background-color: #E8F6ED;
$timeline-wrong-answer-color: #EF4444;
$timeline-wrong-answer-background-color: #FEF2F2;

$timeline-background-color-very-light: #F8F9FA;
$timeline-difficulty-level-high: #EF4444;
$timeline-difficulty-level-none: #e0e0e0;
$timeline-border-color-light-orange: #FFB772;
$timeline-border-color-lighter-orange: #FFD5A9;
$timeline-border-color-light-orange-2: #FD8E3A;
$note-qna-counter-running-background: #F4F7FB;
$note-qna-counter-running-text: #9EBFDB;
$note-qna-counter-stop-background: #346895;
$note-qna-answer-correct-background: #E8F6ED;
$note-qna-answer-correct-color: #16A34A;
$note-qna-answer-wrong-background: #FEEDED;
$note-qna-answer-wrong-color: #EF4444;
$note-qna-card-shadow-color: rgba(0, 0, 0, 0.1);
$note-qna-card-header-img-background: #FFF7ED;
$note-qna-answer-wrong-hover: #F9B4B4;
$note-qna-answer-right-hover: #A2DAB7;
$row-answer-color: #757575;
$text-answer-color: #a5a5a5;
$users-management-icon-color: #FFECD4;
$registration-required-color: #D8E8FF;
$registration-required-border-color: #A2C9FF;
$registration-required-text-color: #0958D9;
$registration-not-required-color: #E8EEF6;
$registration-not-required-border-color: #9EBFDB;
$registration-not-required-text-color: #274765;
$registration-required-sections-color: #D0F3F3;
$registration-required-sections-border-color: #92E1E1;
$registration-required-sections-text-color: #016B6F;
$questionnaire-background-incorrect: #fef2f2;
$questionnaire-border-incorrect: #fecaca;
